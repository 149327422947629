<template>
  <b-button
    class="action-button"
    ref="button"
    variant="link"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <ExistingCommentIcon v-if="value">{{ value }}</ExistingCommentIcon>
    <NewCommentIcon v-else></NewCommentIcon>
  </b-button>
</template>

<script>
export default {
  name: "CommentaireComponent",
  /**
   * value = valeur du commentaire
   * disabled = true pour griser le contrôle
   */
  props: ["value", "disabled"],
};
</script>
