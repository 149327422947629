<template>
  <div v-if="computedStatut && message" class="d-inline-block d-print-none">
    <div ref="icon">
      <b-icon
        :icon="computedStatut.icon"
        :variant="computedStatut.variant"
      ></b-icon>
    </div>
    <b-tooltip
      v-if="message"
      :target="() => $refs['icon']"
      triggers="hover"
      variant="primary"
    >
      {{ message }}
    </b-tooltip>
  </div>
</template>

<script>
import { StatutCommande } from "../../../services/appro.service";
export default {
  name: "StatutCommandeIcon",
  props: ["statut", "message"],
  data: () => ({ statuts: Object.values(StatutCommande) }),
  computed: {
    computedStatut() {
      return this.statuts.find((s) => s.name === this.statut);
    },
  },
};
</script>
