<template>
  <span v-if="value">
    <span class="d-print-none">
      <span v-html="computedText"></span>
      <template v-if="overflow">
        <b-link v-if="truncate" href="#" @click="truncate = !truncate">
          ... (+)
        </b-link>
        <b-link v-else href="#" @click="truncate = !truncate">(-)</b-link>
      </template>
    </span>
    <span class="d-none d-print-inline" v-html="value"></span>
  </span>
</template>

<script>
export default {
  name: "LongTextComponent",
  props: {
    value: String,
    maxSize: { type: Number, default: 200 },
  },
  data: () => ({ truncate: true }),
  computed: {
    overflow() {
      return (this.value?.length ?? 0) > this.maxSize;
    },
    computedText() {
      return this.truncate && this.overflow
        ? this.value.slice(0, this.maxSize)
        : this.value;
    },
  },
};
</script>
