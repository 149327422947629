<template>
  <b-container fluid class="widget-frame p-2 p-md-4">
    <b-row role="button" @click="toggle">
      <b-col cols="auto">
        <h2><b-icon :icon="icon"></b-icon></h2>
      </b-col>
      <b-col class="px-0 px-md-3">
        <h2>{{ titre }}</h2>
      </b-col>
      <b-col cols="auto">
        <h2>
          <b-icon-chevron-up v-if="innerVisible"></b-icon-chevron-up>
          <b-icon-chevron-down v-else></b-icon-chevron-down>
        </h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="px-2 px-md-5">
          <b-collapse
            v-model="innerVisible"
            @show="$emit('show')"
            @shown="$emit('shown')"
            @hide="$emit('hide')"
            @hidden="$emit('hidden')"
          >
            <slot></slot>
          </b-collapse>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "CategorieComponent",
  props: ["icon", "titre", "visible"],
  data: () => ({
    innerVisible: false,
  }),
  created() {
    this.innerVisible = this.visible ?? !this.$store.getters["affichageMobile"];
  },
  methods: {
    show() {
      this.innerVisible = true;
    },
    toggle() {
      this.innerVisible = !this.innerVisible;
    },
    hide() {
      this.innerVisible = false;
    },
  },
};
</script>
