<template>
  <b-modal ref="modal" scrollable @close="resolve()">
    <template #modal-title>S&eacute;lection d'une adresse</template>
    <template v-if="adresses.length > 0">
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <b-row v-for="(adresse, index) in adresses" :key="index">
        <b-col>
          <b-card class="mb-3" role="button" @click="resolve(adresse)">
            <b-card-title>{{ adresse.nomUsage }}</b-card-title>
            <b-card-sub-title>{{ adresse.codeTypeAdresse }}</b-card-sub-title>
            <b-card-text>
              <div v-if="adresse.adresse1">
                {{ adresse.adresse1 }}
              </div>
              <div v-if="adresse.adresse2">
                {{ adresse.adresse2 }}
              </div>
              <div v-if="adresse.commune">
                {{ adresse.commune.codePostal }}
                {{ adresse.commune.libelle }}
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template #modal-footer>
      <b-button block pill variant="outline-primary" @click="resolve()">
        Annuler
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ErrorComponent from "../ErrorComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "SelectAdresseDialog",
  components: { ErrorComponent },
  data: () => ({
    error: null,
    adresses: [],
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    async show(options) {
      this.error = null;
      this.adresses = [];
      try {
        this.adresses = await this.load(options);
      } catch (e) {
        this.error = UtilsService.handleError(e);
      } finally {
        this.$refs["modal"].show();
      }
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async load({ codeTiers, includeTypes, excludeTypes }) {
      // Récupérer toutes les adresses du tiers
      let { data: adresses } = await ExploitationService.getAdresses(codeTiers);

      // Les filtrer en fonction du type d'adresse
      if (includeTypes) {
        adresses = adresses.filter((a) =>
          includeTypes.includes(a.codeTypeAdresse)
        );
      } else if (excludeTypes) {
        adresses = adresses.filter(
          (a) => !excludeTypes.includes(a.codeTypeAdresse)
        );
      }

      // Puis les trier selon le type d'adresse
      adresses.forEach(
        (a) => (a.ordre = this.getOrdreTypeAdresse(a.codeTypeAdresse))
      );
      return adresses.sort(UtilsService.sortByNumberProperty("ordre"));
    },
    getOrdreTypeAdresse(codeTypeAdresse) {
      if (codeTypeAdresse === "CONTACT") {
        return 3;
      }
      if (codeTypeAdresse === "SITE") {
        return 2;
      }
      return 1;
    },
    resolve(adresse) {
      this.$refs["modal"].hide();
      this.resolvePromise(adresse);
    },
  },
};
</script>
