<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>
      <template v-if="title">{{ title }}</template>
      <template v-else>R&eacute;diger un commentaire</template>
    </template>
    <label v-if="labelItems">{{ labelItems }}</label>
    <label v-else>Item(s)</label>
    <ul>
      <li v-for="(item, index) in items" :key="index">
        {{ item }}
      </li>
    </ul>
    <InputCommentaireComponent
      :label="labelCommentaire"
      :showIfEmpty="true"
      :size="size"
      :disabled="disabled"
      v-model="commentaire"
    >
      <em v-if="description">
        {{ description }}
      </em>
    </InputCommentaireComponent>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import InputCommentaireComponent from "../inputs/InputCommentaireComponent.vue";
export default {
  name: "CommentaireDialog",
  components: { InputCommentaireComponent },
  data: () => ({
    id: `comment-${uuidv4()}`,
    title: null,
    labelItems: null,
    items: [],
    labelCommentaire: null,
    description: null,
    size: null,
    disabled: null,
    commentaire: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    show(options = {}) {
      this.title = options.title ? options.title : null;
      this.labelItems = options.labelItems ? options.labelItems : null;
      this.items = options.items ? options.items : [];
      this.labelCommentaire = options.labelCommentaire ?? "Commentaire";
      this.description = options.description ? options.description : null;
      this.size = options?.size;
      this.disabled = options?.disabled;
      this.commentaire = options.commentaire ? options.commentaire : null;
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    validate() {
      this.$refs["modal"].hide();
      this.resolvePromise(this.commentaire);
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
